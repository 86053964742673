<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from 'axios';

import {
  required,
  //email,
  minLength,
  //sameAs,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Site registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title: "Job Position registration",
      items: [
        {
          text: "Company",
          href: "/"
        },
        {
          text: "Job Positions",
          href: "/company/jobpositions"
        },
        {
          text: "Job Position",
          active: true
        }
      ],
      form: {
        id_company: "",
        id_site: "",
        id_department: "",
        id_levelJobPosition: "",
        nm_jobTitle: "",
        de_jobPosition: ""
      },
      submitted: false,
      companies: [],
      sites: [],
      departments: [],
    };
  },
  validations: {
    form: {
      id_company: { required },
      id_site: { required },
      id_department: { required },
      id_levelJobPosition: { required },
      nm_jobTitle: {required, minLength: minLength(2), maxLength: maxLength(50)},
      de_jobPosition: {required, minLength: minLength(100), maxLength: maxLength(2048)},
    }
  },
  watch:{},
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    if( this.$route.params.id ){
      this.getJobPosition();
    }else{
      this.getCompanies();
    }
  },
  methods: {
    async getCompanies(){
      Swal.fire({
        title: "Loading Companies",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        'http://localhost/dist/mvw/controllers/organizations.php',
        {
          params: {
            request: 'getOrganizationsForCombos'
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          app.companies = response.data;
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    async getSites(){
      let app = this;

      Swal.fire({
        title: "Loading Sites",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        'http://localhost/dist/mvw/controllers/sites.php',
        {
          params: {
            request: 'getSitesByCompanyID',
            idCompany: this.form.id_company
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          app.sites = response.data;
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    async getDepartmentsBySiteID(){
      let app = this;

      Swal.fire({
        title: "Loading Departments",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        'http://localhost/dist/mvw/controllers/departments.php',
        {
          params: {
            request: 'getDepartmentsBySiteID',
            idSite: this.form.id_site,
          }
        }
      )
      .then(response => {
        if(response.status == 500){ app.$router.push({ name: 'Error-500' }); }
        if(response.status == 404){ app.$router.push({ name: 'Error-404' }); }
        if(response.status == 401){ app.$router.push({ name: 'Error-401' }); }

        if( response.statusText == "OK" ){
          app.departments = response.data;
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },
    
    async getJobPosition(){
      Swal.fire({
        title: "Loading job position.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      let app = this;

      axios
      .get(
        'http://localhost/dist/mvw/controllers/jobpositions.php',
        {
          params: {
            request: 'getJobPosition',
            id: this.$route.params.id
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          app.jobPosition = response.data;

          this.form.id_company                    = app.jobPosition.id_company;
          this.form.id_site                       = app.jobPosition.id_site;
          this.form.id_department                 = app.jobPosition.id_department;
          this.form.id_levelJobPosition           = app.jobPosition.id_levelJobPosition;
          this.form.nm_jobTitle                   = app.jobPosition.nm_jobTitle;
          this.form.de_jobPosition                = app.jobPosition.de_jobPosition;

          Swal.close();

          this.getCompanies();
          this.getSites();
          this.getDepartmentsBySiteID();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    saveJobPosition(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_jobPosition = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving site.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post('http://localhost/dist/mvw/controllers/jobpositions.php', {
          request: 'saveJobPosition',
          data: this.form, 
        })
        .then(function (response) {
          let action = "";
          if( response.data === "OK" ){
            action = "saved";
          }
          if( response.data === "UPDATED" ){
            action = "updated";
          }

          Swal.fire("Good job!", "Your JOb Position is correct "+action+"!", "success");
          app.$router.push({ name: 'job-positions' });
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },
    resetFromCompany(){
      this.sites                    = [];
      this.departments              = [];
      this.form.id_site             = "";
      this.form.id_department       = "";
      this.form.id_levelJobPosition = "";
    },
    resetFromSite(){
      this.departments              = [];
      this.form.id_department       = "";
      this.form.id_levelJobPosition = "";
    }

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveJobPosition">
              <div class="row">
                <div class="col-lg-4">
                  <!--<h4 class="card-title">Department Data</h4>-->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="id_company">Company</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_company.$error }"
                          name="id_company"
                          id="id_company"
                          v-model="form.id_company"
                          @change="resetFromCompany();getSites()"
                        >
                          <option value="">SELECT YOUR COMPANY</option>
                          <option v-for="(company, index) in companies" :key="index" :value="company.id_company">{{company.nm_company}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_company.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_company.required">Company field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_site">Site</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_site.$error }"
                          name="id_site"
                          id="id_site"
                          v-model="form.id_site"
                          @change="resetFromSite();getDepartmentsBySiteID()"
                        >
                          <option value="">SELECT YOUR SITE</option>
                          <option v-for="(site, index) in sites" :key="index" :value="site.id_site">{{site.nm_site}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_site.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_site.required">Site is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_department">Department</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_department.$error }"
                          name="id_department"
                          id="id_department"
                          v-model="form.id_department"
                        >
                          <option value="">SELECT YOUR DEPARTMENT</option>
                          <option v-for="(department, index) in departments" :key="index" :value="department.id_department">{{department.nm_department}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_department.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_department.required">Site is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_levelJobPosition">Position Level</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_levelJobPosition.$error }"
                          name="id_levelJobPosition"
                          id="id_levelJobPosition"
                          v-model="form.id_levelJobPosition"
                        >
                          <option value="">SELECT YOUR SITE</option>
                          <option value="1">TOP MANAGEMENT</option>
                          <option value="2">SITE DIRECTOR</option>
                          <option value="3">MANAGER</option>
                          <option value="4">SUPERVISOR</option>
                          <option value="5">EMPLOYEE</option>
                        </select>
                        <div v-if="submitted && $v.form.id_levelJobPosition.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_levelJobPosition.required">Level Position is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="nm_jobTitle">Job Title name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_jobTitle.$error }"
                          name="nm_jobTitle"
                          id="nm_jobTitle"
                          v-model="form.nm_jobTitle"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_jobTitle.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_jobTitle.required">Department name is required.</span>
                          <span v-if="!$v.form.nm_jobTitle.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_jobTitle.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--
                  <p class="card-title-desc">To understand better your company, please provide each department of your current site. This will help you to know your Departmental Structure.</p>
                  -->
                </div>

                <div class="col-lg-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="de_jobPosition">Job Position description</label>
                        <textarea
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_jobPosition.$error }"
                          name="de_jobPosition"
                          id="de_jobPosition"
                          v-model="form.de_jobPosition"
                          rows="17"
                          value=""
                        >
                        </textarea>
                        <div v-if="submitted && $v.form.de_jobPosition.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_jobPosition.required">Address field is required.</span>
                          <span v-if="!$v.form.de_jobPosition.minLength">This value length is invalid. It should be between 100 and 200 characters long.</span>
                          <span v-if="!$v.form.de_jobPosition.maxLength">This value length is invalid. It should be between 100 and 200 characters long.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card-title">&nbsp;</div>
                    <p class="card-title-desc">At every company, there’s a job position that summarizes the work employees does. Companies use job positions to better clarify each employee’s role, outlining their responsibilities. <!--Learning more about how it contribute to employees work position can help you better describe their role on their resume.--></p>

                    <p class="card-title-desc">A job position is a function you serve at a company. It includes the daily tasks and projects you complete. Every employee has a job position that includes specific duties and responsibilities that help the company reach its goals.</p>

                    <p class="card-title-desc">A job title is a label your company gives you, while a job position describes your responsibilities.</p>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">SAVE JOB POS.</button>
                    <router-link to="/company/jobpositions" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>